






































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import Filters from '@/utils/Filters';
import {FileTransferType} from '@/enums/FileTransferType';
import DownloadButton from '@/components/commons/DownloadButton.vue';
import DropdownButton from '@/components/freight/DropdownButton.vue';

Vue.use(Filters);

const AuthStore = namespace('AuthStore');

@Component({
    name: 'FreightRowAction',
    components: {DropdownButton, DownloadButton},
})
export default class FreightRowAction extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public rowData: any;

    @Prop()
    public index!: number;

    @Prop()
    public numberRows!: number;

    public copied: boolean = false;

    public baseUrl: string = '/cte/download';

    public xml: FileTransferType = FileTransferType.XML;

    public dialogVisible: boolean = false;

    public filterFormatCurrency = Vue.filter('formatCurrency');

    get keyPopperClass(): string {
        return this.copied ? 'copied' : 'normal';
    }

    public hasPerm(): any {
        return this.$attrs.permissaocomprovante;
    }

    public onKeyHide(): void {
        this.copied = false;
    }

    public onKeyClick(value: string): void {
        this.$copyToClipboard(value);
        this.copied = true;
    }

    public downloadOptList(rowData): any {
        return [
            {
                descricao: rowData.formFileType &&
                (rowData.formFileType.endsWith('.jpg') || rowData.formFileType.endsWith('.jpeg')) ?
                    "JPG" : "PDF",
                disabled: !rowData.comprovanteForm,
                icon: rowData.formFileType &&
                    (rowData.formFileType.endsWith('.jpg') || rowData.formFileType.endsWith('.jpeg')) ?
                        'file-image' : 'file-pdf',
                params: {
                    fileName: 'comprovante_' + rowData.chave,
                    data: {cteId: rowData.id, tpDownload: 1},
                    type: rowData.formFileType &&
                            (rowData.formFileType.endsWith('.jpg') || rowData.formFileType.endsWith('.jpeg')) ?
                        FileTransferType.JPG : FileTransferType.PDF,
                },
            },
            {
                descricao: 'App Entrega',
                disabled: !rowData.comprovanteApp,
                icon: 'mobile',
                params: {
                    fileName: 'comprovante_' + rowData.chave,
                    data: {cteId: rowData.id, tpDownload: 2},
                    type: FileTransferType.JPG,
                },
            },
            {
                descricao: 'Scanner',
                disabled: !rowData.comprovanteScan,
                icon: 'barcode',
                params: {
                    fileName: 'comprovante_' + rowData.chave,
                    data: {cteId: rowData.id, tpDownload: 0},
                    type: rowData.scanFileType &&
                            (rowData.scanFileType.endsWith('.jpg') || rowData.scanFileType.endsWith('.jpeg')) ?
                        FileTransferType.JPG : FileTransferType.PDF,
                },
            },
            {
                descricao: 'Todos',
                disabled: this.onlyOneTrue(rowData.comprovanteScan, rowData.comprovanteApp, rowData.comprovanteForm),
                icon: 'file-archive',
                params: {
                    fileName: 'comprovantes_' + rowData.chave,
                    data: {cteId: rowData.id, tpDownload: 3},
                    type: FileTransferType.ZIP,
                },
            },
        ];
    }

    public onlyOneTrue(scan, app, form) {
        return (scan && !app && !form) || (!scan && app && !form) || (!scan && !app && form);
    }

    public compDownloadCallback = (response) => {
        if (response.status !== 200) {
            if (response.data && typeof response.data === 'string') {
                this.$toast.warning(response.data);
            } else {
                this.$toast.warning('Não foi possível realizar o download do comprovante.');
            }
        }
    }
}
